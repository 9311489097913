import React, { useState, useEffect } from 'react';
import {authorizationCode}  from './../../utils/open-id';
import Style from "./Login.module.scss";

const LoginCallback = (props:any) => {
  const querySearch = props.location.search;
  const queryParams = new URLSearchParams(querySearch);
  const errorParam = queryParams.get('error');
  const errorDescParam = queryParams.get('error_description');

  const [errorMsg] = useState({
    error: errorParam,
    errorDesc: errorDescParam
  });

  const getGrantURICallback = () => {
    authorizationCode.callback({
      current_uri: window.location.href,
      success_uri: `${window.location.origin}/success`
    }).catch((err:any) => {
      console.log('ERR', err);
    });
  };

  useEffect(() => {
    if (errorDescParam === 'End-User aborted interaction') {
      //@ts-ignore
      window.location = '/auth/login'
    }

    getGrantURICallback();
  }, []);

  return (
    <>
      {errorMsg.error && (
        <section className={Style.wrapper}>
          <div className={Style.container}>
            <div className={Style.imageLogo}>
              <img alt='404' src='./assets/image/BaseElement/icon-404.svg' />
            </div>
            <h5>Error: {errorMsg.error}</h5>
            <p>{errorMsg.errorDesc}</p>
            <a href="/auth/login" className={Style.btnLink}>
              Kembali ke Halaman Login
            </a>
          </div>
        </section>
      )}
    </>
  );
};

export default LoginCallback;
