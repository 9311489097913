// import {Mutex} from 'async-mutex'
import axios, {AxiosStatic} from 'axios'
import AuthRedux from 'src/app/modules/auth/redux/AuthRedux'
import { Token } from 'src/app/helper/token'
import Cookies from 'js-cookie'

// const mutex = new Mutex()
const plainAxios = axios.create()

export default function setupAxios(localAxios: AxiosStatic, store: any) {
  localAxios.defaults.baseURL = process.env.REACT_APP_API_URL
  plainAxios.defaults.baseURL = process.env.REACT_APP_API_URL

  localAxios.interceptors.request.use(
    (config: any) => {
      const access_token = Cookies.get('access_token') // NEW
      // const { auth: {accessToken}, } = store.getState() // OLD
      if (access_token && !config.headers.Authorization) {
        config.headers.Authorization = `Bearer ${access_token}`
      }
      return config
    },
    (err: any) => {
      Promise.reject(err)
    }
  )

  localAxios.interceptors.response.use(
    (response: any) => response,
    async (error: any) => {
      const access_token = Cookies.get('access_token') // NEW
      const refresh_token = Cookies.get('refresh_token') // NEW
      const originalRequest = error.config
      // const { auth: {refreshToken}, } = store.getState() // OLD
      const {dispatch} = store // OLD

      const {status} = error.response ?? {}
      if (status === 401 && refresh_token != null) {
        try{
          let res = await Token.resolveUserToken(); // NEW
          if(res === "Unauthorized"){
            dispatch(AuthRedux.actions.logout())
          }else{
            originalRequest.headers['Authorization'] =  `Bearer ${access_token}`
            return axios(originalRequest)
          }
        }catch(err){
          console.log(err)
        }
        // window.location.reload();
        
        // OLD
        // return mutex.runExclusive(async () => {
        //   console.log("cek token 4 => ",refreshToken)
        //   // const refresh = await _refreshToken(refreshToken)
        //   console.log("cek token 5 => ",refresh)
        //   if (refresh) {
        //     console.log("cek token 6 => ",refresh)
        //     error.config.headers.Authorization = `Bearer ${access_token}`
        //     try {
        //       console.log("cek token 7 => ",error.config)
        //       return await plainAxios.request(error.config)
        //     } catch (err: any) {
        //       console.log("cek token 8 => ",err)
        //       if (err.response?.status === 401) dispatch(AuthRedux.actions.logout())
        //       throw err
        //     }
        //   }
        //   dispatch(AuthRedux.actions.logout())
        //   throw error
        // })
      }else if(status === 401 && !refresh_token){
        dispatch(AuthRedux.actions.logout())
      }else{
        throw error
      }
    }
  )

  const _refreshToken = async (refreshToken: any) => {
    const {dispatch} = store
    try {
      const result = await plainAxios.post(`/api/v1/admins/refresh-token`, null, {
        headers: {
          Authorization: `Bearer ${refreshToken}`,
        },
      })
      if (result.data)
        dispatch(
          AuthRedux.actions.fulfillToken({
            accessToken: result.data.data.token,
            refreshToken: refreshToken,
          })
        )
      return result.data
    } catch (error: any) {
      if (error.response.data?.message[0]?.constraint[0]?.code === 'REFRESH_TOKEN_UNAUTHORIZED') {
        dispatch(AuthRedux.actions.logout('User telah login di tempat lain'))
      } else {
        dispatch(AuthRedux.actions.logout())
      }
      throw error
    }
  }
}
