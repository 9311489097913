import React from 'react'
import {Redirect, Route, Switch} from 'react-router-dom'
import {Dashboard} from './page/Dashboard'
import DashboardScreen from './Screens'

const DashboardRoutes: React.FC = () => {
  return (
    <Switch>
      <Route path={DashboardScreen.DASHBOARD.PATH} component={Dashboard} />
      <Redirect from='/dashboard' exact={true} to={DashboardScreen.DASHBOARD.PATH} />
    </Switch>
  )
}

export default DashboardRoutes
