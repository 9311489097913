import {Doughnut} from 'react-chartjs-2'
import InlineSVG from 'react-inlinesvg'

const data: any = {
  datasets: [
    {
      data: [25000, 300000],
      backgroundColor: ['rgb(221, 221, 221)', 'rgb(38, 186, 253)'],
    },
  ],
}
const data2: any = {
  datasets: [
    {
      data: [18200, 100],
      backgroundColor: ['rgb(221, 221, 221)', 'rgb(249, 180, 79)'],
    },
  ],
}
const options: any = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      display: true,
      position: 'right',
    },
  },
  cutout: '60%',
}

const VoucherQuotaPlatform: React.FC = () => {
  return (
    <div className='voucher-quota-card'>
      <div className='title-text-dashboard'>Voucher Quota by Platform</div>
      <div className='dashboard-platform-wrapper'>
        <div className='content-shop-wrapper' style={{marginBottom: '10px'}}>
          <div className='content-shop-detail' >
            <div className='num-text-dashboard' >
              SHOP{' '}
              <InlineSVG style={{width: '24px', height: '24px'}} src={'/media/svg/misc/shop.svg'} />
            </div>
            <div className='content-detail' >
              <div className='data-detail'>
                <div className='title-label'>Total</div>
                <div className='num-text-dashboard'>325.000</div>
              </div>
              <div className='data-detail'>
                <div className='title-label'>Claimed</div>
                <div className='num-text-dashboard'>300.00</div>
              </div>
              <div className='data-detail'>
                <div className='title-label'>Remaining</div>
                <div className='num-text-dashboard'>25.000</div>
              </div>
            </div>
          </div>
          <div
            className='chart-platform'
            style={{position: 'relative'}}
          >
            <Doughnut data={data} options={options} />
            <div
              style={{
                position: 'absolute',
                top: '50%',
                left: '40%',
                transform: 'translate(-50%, -50%)',
                textAlign: 'center',
              }}
            >
              <div style={{fontSize: '12px', lineHeight: '19px', fontWeight: 600}}>92%</div>
            </div>
          </div>
        </div>
        <div className='content-food-wrapper'>
          <div className='content-food-detail'>
            <div className='num-text-dashboard'>
              FOOD{' '}
              <InlineSVG style={{width: '24px', height: '24px'}} src={'/media/svg/misc/food.svg'} />{' '}
            </div>
            <div className='content-detail'>
              <div className='data-detail'>
                <div className='title-label'>Total</div>
                <div className='num-text-dashboard'>18.300</div>
              </div>
              <div className='data-detail'>
                <div className='title-label'>Claimed</div>
                <div className='num-text-dashboard'>18.200</div>
              </div>
              <div className='data-detail'>
                <div className='title-label'>Remaining</div>
                <div className='num-text-dashboard'>100</div>
              </div>
            </div>
          </div>
          <div
            className='chart-platform'
            style={{position: 'relative'}}
          >
            <Doughnut data={data2} options={options} />
            <div
              style={{
                position: 'absolute',
                top: '50%',
                left: '40%',
                transform: 'translate(-50%, -50%)',
                textAlign: 'center',
              }}
            >
              <div style={{fontSize: '12px', lineHeight: '19px', fontWeight: 600}}>0.5%</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default VoucherQuotaPlatform
