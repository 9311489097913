import RefreshToken from "svc-auth-sdk-node/src/account-center/grant/refresh-token";
import { resolve, destroy } from "svc-auth-sdk-node/src/account-center/token";
import { getHost } from "svc-auth-sdk-node/src/account-center/host";
import jwtDecode from 'jwt-decode';
import Cookies from "js-cookie";

export class Token {
  static refreshUserToken = async (token = '') => {
    const refreshToken = Cookies.get('refresh_token') || token;

    const refreshObj = new RefreshToken();
    const generateToken = await refreshObj.token(refreshToken);

    if (generateToken?.access_token) {
      Cookies.set('access_token', generateToken?.access_token, { expires: 7, path: "/" });
      Cookies.set('refresh_token', generateToken?.refresh_token, { expires: 7, path: "/" });
      Cookies.set('id_token', generateToken?.id_token, { expires: 7, path: "/" });
    } else {
      this.removeUserToken();
    }

    return generateToken;
  }

  static removeUserToken = async (token = '', redirect = true) => {
    const userToken = Cookies.get('access_token') || token;

    await destroy(userToken);

    Cookies.remove('access_token');
    Cookies.remove('refresh_token');
    Cookies.remove('id_token');
    Cookies.remove('access_token', { domain: process.env.REACT_APP_AUTH_COOKIE_DOMAIN });
    Cookies.remove('refresh_token', { domain: process.env.REACT_APP_AUTH_COOKIE_DOMAIN });
    Cookies.remove('id_token', { domain: process.env.REACT_APP_AUTH_COOKIE_DOMAIN });

    if (redirect) window.location.replace(`${getHost()}/logout?callback=${window.location.origin}/auth/login`);
  }

  static resolveUserToken = async (token = '') => {
    const userToken = Cookies.get('access_token') || token;
    const refreshToken = Cookies.get('refresh_token') || '';

    if (userToken === 'undefined' || !userToken) {
      this.removeUserToken();
    } else {
      const decodeToken = jwtDecode(userToken);

      if (decodeToken?.exp < (new Date() / 1000)) {
        await this.refreshUserToken(refreshToken);
        this.resolveUserToken();
      } else {
        const resolveToken = await resolve(userToken);
        if(resolveToken.error === "Unauthorized"){
          return 'Unauthorized'
        }else{
          return resolveToken;
        }
      }
    }
  }
}