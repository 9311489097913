/* eslint-disable react-hooks/exhaustive-deps */
import {FC, useEffect} from 'react'
import {PageTitle} from 'src/_metronic/layout/core'
import {getTitle} from 'src/app/utils/title-utils'
import DashboardScreens from '../Screens'
import VoucherQuota from '../components/VoucherQuota'
import VoucherQuotaPlatform from '../components/VoucherQuotaPlatform'
import CampaignStatus from '../components/CampaignStatus'
import MostClaimedCampaign from '../components/MostClaimedCampaign'
import MostClaimPeople from '../components/MostClaimPeople'
import DateRangePicker from 'src/app/components/DateRangePicker'
// import moment from 'moment'

const Dashboard: FC = (props: any) => {
  useEffect(() => {
    document.title = getTitle('List of Verified')
  }, [])

  return (
    <>
      <PageTitle>Dashboard</PageTitle>
      <div className='card shadow-sm'>
        <div className='card-tab d-flex align-items-center justify-content-between'>
          <div className='flex-fill fs-2 fw-bolder'>{DashboardScreens.DASHBOARD.TITLE}</div>
        </div>
        <div className='card-body'>
          <div className='dashboard-filter-group'>
            <div className='position-relative w-lg-250px w-200' style={{marginRight: '20px'}}>
              <DateRangePicker className='' onChange={() => {}} format='DD MMM YYYY' />
            </div>
            <div className='filter-dashboard-off' style={{marginRight: '20px'}}>
              Today
            </div>
            <div className='filter-dashboard-off' style={{marginRight: '20px'}}>
              7 Last days
            </div>
            <div className='filter-dashboard-on' style={{marginRight: '20px'}}>
              This Month
            </div>
            <div className='filter-dashboard-off'>This Year</div>
          </div>
          <div className='dashboard-chart-group' style={{marginTop: '25px', marginBottom: '25px'}}>
            <div style={{marginRight: '15px'}}>
              <VoucherQuota />
            </div>
            <div style={{marginRight: '15px'}}>
              <VoucherQuotaPlatform />
            </div>
            <CampaignStatus></CampaignStatus>
          </div>
          <div className='dashboard-chart-group'>
            <div style={{marginRight: '20px'}}>
              <MostClaimedCampaign />
            </div>
            <MostClaimPeople />
          </div>
        </div>
      </div>
    </>
  )
}

export {Dashboard}
