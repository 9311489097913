/* eslint-disable jsx-a11y/anchor-is-valid */
import {useEffect} from 'react'
import {getTitle} from 'src/app/utils/title-utils'
import {authorizationCode} from 'src/app/utils/open-id'

export function LoginEmail() {
  useEffect(() => {
    document.title = getTitle('Login')
  }, [])

  return (
    <>
      <div className='text-center mb-10'>
        <h1 className='text-dark mb-3 fs-2'>Campaign Management</h1>
      </div>
      <div className='text-center'>
        <button
          type='submit'
          id='kt_sign_in_submit'
          className='btn btn-lg btn-secondary w-100 mb-5'
          tabIndex={3}
          onClick={() => authorizationCode.consent()}
        >
         <span className='indicator-label'>Login</span>
        </button>
      </div>
    </>
  )
}
