import InlineSVG from 'react-inlinesvg'

const data: any = [
  {
    name: 'SALE 30% Januari Ceria',
    total: 1124,
    lastClaimed: '1 January 2023',
    platform: 'SHOP',
    status: 1,
  },
]

const MostClaimedCampaign: React.FC = () => {
  return (
    <div className='card-dashboard'>
      <div className='title-text-dashboard'>Most Claimed Campaign</div>
      <div className='position-relative'>
        <div className='table-responsive my-8'>
          <table className='table table-striped table-hover gx-4 gy-4'>
            <thead className='text-gray fw-700'>
              <tr>
                <th>No</th>
                <th
                  style={{
                    whiteSpace: 'nowrap',
                  }}
                >
                  Campaign Name
                </th>
                <th
                  style={{
                    whiteSpace: 'nowrap',
                  }}
                >
                  Total Claimed
                </th>
                <th
                  style={{
                    whiteSpace: 'nowrap',
                  }}
                >
                  Last Claimed
                </th>
                <th>Platform</th>
                <th>Status</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {data.map((value: any, index: any) => (
                <tr key={index}>
                  <td className='align-middle'> {index + 1}</td>
                  <td className='align-middle'>{value.name}</td>
                  <td className='align-middle'> {value.total}</td>
                  <td className='align-middle'>{value.lastClaimed}</td>
                  <td className='align-middle'>{value.platform}</td>
                  <td className='align-middle'>
                    <span
                      style={{
                        color: value.status === 1 ? '#22C55E' : '#666666',
                        textAlign: 'center',
                        fontSize: 10,
                        flexDirection: 'row',
                      }}
                    >
                      {value.status === 1 ? 'Active' : 'Inactive'}
                    </span>
                  </td>
                  <td className='align-middle' style={{minWidth: 125}}>
                    <div
                      className='d-inline'
                      data-bs-toggle='modal'
                      data-bs-target='#kt_modal_scrollable_2'
                      style={{cursor: 'pointer'}}
                      onClick={() => {}}
                    >
                      <InlineSVG src={'/media/icons/eye.svg'} />
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}

export default MostClaimedCampaign
