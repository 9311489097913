import React from 'react'
import {authorizationCode} from '../utils/open-id'

const Login = () => {
  return (
    <div>Login
      <button onClick={()=>authorizationCode.consent()}>LOGIN</button>
    </div>
  )
}

export default Login
