import {Pie} from 'react-chartjs-2'

const data: any = {
  datasets: [
    {
      data: [12, 6, 22, 7, 154],
      backgroundColor: [
        'rgb(255, 180, 0)',
        'rgb(255, 76, 83)',
        'rgb(136, 136, 136)',
        'rgb(221, 221, 221)',
        'rgba(2, 239, 139)',
      ],
    },
  ],
}
const options: any = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      display: true,
      position: 'right',
    },
  },
  rotation: -Math.PI,
}

const CampaignStatus: React.FC = () => {
  return (
    <div className='voucher-quota-card'>
      <div className='title-text-dashboard'>Campaign Status</div>
      <div className='dashboard-content-wrapper'>
        <div className='card-wrapper' style={{marginRight: '70px'}}>
          <div style={{display: 'flex', flexDirection: 'row'}}>
            <div className='circle-label' style={{backgroundColor: '#02EF8B'}} />
            <div style={{marginBottom: '5px'}}>
              <div className='label-campaign'>Active</div>
              <div className='num-text-dashboard'>153</div>
            </div>
          </div>
          <div style={{display: 'flex', flexDirection: 'row'}}>
            <div
              className='circle-label'
              style={{
                backgroundColor: '#FFB400',
              }}
            />
            <div  style={{marginBottom: '5px'}}>
              <div className='label-campaign'>Need Approval</div>
              <div className='num-text-dashboard'>12</div>
            </div>
          </div>
          <div style={{display: 'flex', flexDirection: 'row'}}>
            <div
              className='circle-label'
              style={{
                backgroundColor: '#FF4C53',
              }}
            />
            <div  style={{marginBottom: '5px'}}>
              <div className='label-campaign'>Revision Required</div>
              <div className='num-text-dashboard'>6</div>
            </div>
          </div>
          <div style={{display: 'flex', flexDirection: 'row'}}>
            <div
              className='circle-label'
              style={{
                backgroundColor: '#888888',
              }}
            />
            <div  style={{marginBottom: '5px'}}>
              <div className='label-campaign'>Inactive</div>
              <div className='num-text-dashboard'>22</div>
            </div>
          </div>
          <div style={{display: 'flex', flexDirection: 'row'}}>
            <div
              className='circle-label'
              style={{
                backgroundColor: '#DDDDDD',
              }}
            />
            <div>
              <div className='label-campaign'>Draft</div>
              <div className='num-text-dashboard'>7</div>
            </div>
          </div>
        </div>
        <div
          className='chart-campaign'
          style={{position: 'relative', width: '160px', height: '160px'}}
        >
          <Pie data={data} options={options} />
        </div>
      </div>
    </div>
  )
}

export default CampaignStatus
