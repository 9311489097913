import InlineSVG from 'react-inlinesvg'

const data: any = [
  {
    name: 'SALE 30% Januari Ceria',
    total: 103,
  },
]

const MostClaimPeople: React.FC = () => {
  return (
    <div className='card-dashboard'>
      <div className='title-text-dashboard'>Most Claimed Campaign</div>
      <div className='position-relative'>
        <div className='table-responsive my-8'>
          <table className='table table-striped table-hover gx-4 gy-4'>
            <tbody>
              {data.map((value: any, index: any) => (
                <tr key={index}>
                  <td className='align-middle'> {index + 1}</td>
                  <td className='align-middle' >
                    <div
                      className='d-inline'
                      data-bs-toggle='modal'
                      data-bs-target='#kt_modal_scrollable_2'
                      style={{cursor: 'pointer'}}
                      onClick={() => {}}
                    >
                      <InlineSVG src={'/media/icons/eye.svg'} />
                    </div>
                  </td>
                  <td className='align-middle'>{value.name}</td>
                  <td className='align-middle'> {value.total}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}

export default MostClaimPeople
