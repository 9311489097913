import React, {useEffect, useState} from 'react'
import {useDispatch} from 'react-redux'
import {useHistory, useLocation} from 'react-router-dom'
import {checkAdmin} from './auth/redux/AuthCRUD'
import AuthRedux from './auth/redux/AuthRedux'
import Cookies from 'js-cookie'

function useQuery() {
  const {search} = useLocation()
  return React.useMemo(() => new URLSearchParams(search), [search])
}

const Success = () => {
  const dispatch = useDispatch()
  const query = useQuery()
  const history = useHistory()

  const [loading, setLoading] = useState<any>(false)
  const [token, setToken] = useState<any>()
  const [isAdmin, setIsAdmin] = useState<boolean>(false)
  const [dataAdmin, setDataAdmin] = useState<any>()

  useEffect(() => {
    setLoading(true)
    setToken({
      access_token: query.get('access_token'),
      id_token: query.get('id_token'),
      refresh_token: query.get('refresh_token'),
    })
    Cookies.set('access_token', query.get('access_token') || "", { expires: 7, path: "/" });
    Cookies.set('refresh_token', query.get('refresh_token') || "", { expires: 7, path: "/" });
  }, [])

  useEffect(() => {
    if (token) {
      checkAdmin(token?.access_token)
        .then((res) => {
          if (res.status === 200) {
            setDataAdmin({
              name: res.data.data?.name,
              position: res.data.data?.position,
            })
            setIsAdmin(true)
          } else {
            setIsAdmin(false)
          }
        })
        .catch((err) => console.log(err))
        .finally(() => setLoading(false))
    }
  }, [token])

  useEffect(() => {
    console.log("ini data is ADMIN ===> ",isAdmin)
    if (isAdmin) {
      dispatch(AuthRedux.actions.login(token?.access_token, token?.refresh_token))
      dispatch(AuthRedux.actions.setUser(dataAdmin))
    }
  }, [isAdmin])

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
        background: 'rgba(43, 190, 203, 1)',
      }}
    >
      {loading ? (
        <div>Checking...</div>
      ) : (
        <div>
          {isAdmin ? (
            <div>
              <h1 style={{ textAlign:'center' }}>Welcome,<br/>{dataAdmin?.name}</h1>
              <button
                className='btn btn-lg btn-secondary w-100 mb-5 mt-5'
                onClick={() => history.push(`/voucher/list`)}
              >
                Home
              </button>
            </div>
          ) : (
            <div>
              <h1>Unauthorized!</h1>
              <button
                className='btn btn-lg btn-secondary w-100 mb-5 mt-5'
                onClick={() => history.push(`/auth/login`)}
              >
                Back
              </button>
            </div>
          )}
        </div>
      )}
    </div>
  )
}

export default Success
