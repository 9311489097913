import {Doughnut} from 'react-chartjs-2'

const data: any = {
  datasets: [
    {
      data: [43200, 300100],
      backgroundColor: ['rgb(221, 221, 221)', 'rgb(255, 52, 172)'],
    },
  ],
}
const options: any = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      display: true,
      position: 'right',
    },
  },
  rotation: -Math.PI,
  cutout: '55%',
}

const VoucherQuota: React.FC = () => {
  return (
    <div className='voucher-quota-card'>
      <div className='title-text-dashboard'>Voucher Quota</div>
      <div className='dashboard-content-wrapper'>
        <div className='card-wrapper' style={{marginRight: '90px'}}>
          <div style={{marginBottom: '15px'}}>
            <div className='title-label'>Total</div>
            <div className='num-text-dashboard'>343.300</div>
          </div>
          <div>
            <div className='title-label'>Claimed</div>
            <div className='num-text-dashboard'>43.200</div>
          </div>
          <div style={{marginTop: '15px'}}>
            <div className='title-label'>Remaining</div>
            <div className='num-text-dashboard'>300.100</div>
          </div>
        </div>
        <div style={{position: 'relative', width: '160px', height: '160px'}}>
          <Doughnut data={data} options={options} />
          <div
            style={{
              position: 'absolute',
              top: '50%',
              left: '46%',
              transform: 'translate(-50%, -50%)',
              textAlign: 'center',
            }}
          >
            <div className='title-text-dashboard'>80%</div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default VoucherQuota
